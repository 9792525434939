<template>
  <div class="buy_fiat">
    <div class="buy_box">
      <div class="b_title">Hicoin</div>
      <div class="b_inp">
        <el-input
          v-model="amount"
          :placeholder="$t('main.text87')"
          @input="amount_input"
          oninput="value=((value.replace(/[^0-9.]/g,'')).replace(/([0-9]+\.[0-9]{2})[0-9]*/,'$1'))"
        ></el-input>
      </div>

      <div class="b_title">{{$t('main.text91')}}</div>
      <div class="b_inp">
        <el-select v-model="value1" @change="value1_change" placeholder=" ">
          <el-option
            v-for="(item,index) in select_arr1"
            :key="index"
            :label="item"
            :value="item">
          </el-option>
        </el-select>
      </div>

      <div class="b_title">{{$t('main.text92')}}</div>
      <div class="b_inp">
        <el-select v-model="value2" @change="value2_change" :placeholder="$t('main.text96')">
          <el-option
            v-for="(item,index) in select_arr2"
            :key="index"
            :label="item.country_name"
            :value="item.agentUserid">
          </el-option>
        </el-select>
      </div>
      
      <div class="b_title">{{$t('main.text69')}}</div>
      <div class="b_inp">
        <el-input v-model="to_amount" disabled></el-input>
      </div>
      <div class="btm_txt">{{$t('main.text94')}} {{price_buy}} {{value1}}/Hicoin</div>
      <div class="btm_txt">{{$t('main.text95')}} {{service_fee}} Hicoin</div>

      <div class="add_txt">
        <span @click="$router.push('/add_account')">{{
          $t("main.text70")
        }}</span>
      </div>
      <div
        class="data_box"
        @click="
          $router.push({
            path: '/set/set_account',
            query: { to_title: true },
          })
        "
      >
        <div class="d_box_left">
          <div class="l_left">
            <div>{{ $t("main.text17") }}</div>
            <div>{{ $t("main.text18") }}</div>
            <div>{{ $t("main.text19") }}</div>
          </div>
          <div class="l_left l_right">
            <div>{{ edit_data ? edit_data.payee_bank_name : "-" }}</div>
            <div>{{ edit_data ? edit_data.payee_account_name : "-" }}</div>
            <div>{{ edit_data ? edit_data.payee_card_num : "-" }}</div>
          </div>
        </div>
        <div class="d_box_right">
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>

      <div class="b_btn">
        <el-button
          class="btn_ok buy_btn"
          v-if="amount&&to_amount&&price_buy&&service_fee&& this.edit_data"
          @click="buy_btn_fun"
          >{{ $t("main.text71") }}</el-button
        >
        <el-button class="btn_no buy_btn" v-else>{{
          $t("main.text71")
        }}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getFiatPrice, orders_create } from "../../../api/api";
import BigNumber from "bignumber.js";

export default {
  computed: {
    edit_data() {
      return JSON.parse(sessionStorage.getItem("edit_data"));
    },
  },
  data() {
    return {
      amount: "",
      value1:"",
      value2:"",
      priceInfo:{},
      userFeeConfig:{},
      select_arr1:[],
      select_arr2:[],

      to_amount:"",//支付金额
      price_buy:"",//汇率
      service_fee:"",//服务费
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    buy_btn_fun() {
      let price_buy = this.priceInfo[this.value1].find(item => item.agentUserid === this.value2)?.price.sell;//汇率
      orders_create({
        fromAmount: this.amount,
        price: price_buy,
        toAmount: this.to_amount,
        currency: this.value1,
        type: 2,
        agentUserid: this.value2,
        payee_info_id: this.edit_data.tx_id,
        userFee:this.service_fee
      }).then((res) => {
        if (res.data.code == 200) {
          this.$router.push({
            path: "/buy_fiat_info",
            query: {
              order_id: res.data.data.order_id,
            },
          });
        } else {
          this.msg("error", res.data.msg);
        }
      });
    },
    getList() {
      getFiatPrice({
        type: 2,
      }).then((res) => {
        if (res.data.code == 200) {
          this.priceInfo = res.data.data.priceInfo;
          this.userFeeConfig = res.data.data.userFeeConfig;
          this.select_arr1 = Object.keys(this.priceInfo);
          this.value1 = this.select_arr1[0];
          this.value1_change();
        }
      });
    },
    amount_input(){
      if(!this.amount) return;
      this.calc_amount();
    },
    value1_change(){
      this.select_arr2 = this.priceInfo[this.value1];
      // this.value2 = this.select_arr2[0].agentUserid;
      this.value2 = "";
      this.calc_amount();
    },
    value2_change(){
      this.calc_amount();
    },
    // 计算
    calc_amount(){
      if(!this.amount||!this.value1||!this.value2) return;
      this.price_buy = this.priceInfo[this.value1].find(item => item.agentUserid === this.value2)?.price.sell;//汇率
      this.to_amount='';//支付金额
      this.service_fee='';//服务费

      let fee=BigNumber(this.amount).times(this.userFeeConfig.rate).toFixed(2,1);
      // x.gt(y) // isGreaterThan 的简写，是否大于
      if(BigNumber(fee).gt(this.userFeeConfig.fee)){
        this.service_fee = fee*1;//服务费
      }else{
        this.service_fee = this.userFeeConfig.fee*1;//服务费
      }
      // ******
      // 用户出售 toAmount = （输入数量 - 用户手续费）* 价格
      this.to_amount = BigNumber(this.amount).minus(this.service_fee).times(this.price_buy).toFixed(2,1)*1;

    }
  },
  
};
</script>

<style scoped>
.buy_box {
  border-radius: 0.375rem;
  background: #fff;
  box-sizing: border-box;
  padding: 1.25rem;
}
.b_title {
  color: #000;
  font-size: 0.875rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}
.b_inp .el-input {
  height: 2.5rem;
}
.b_inp {
  margin-bottom: 1rem;
}
/* .b_inp2 {
  display: flex;
  height: 2.5rem;
  line-height: 2.5rem;
} */
.buy_btn {
  margin-top: 2.5rem;
  height: 2.5rem;
  border-radius: 0.25rem;
  font-size: 0.875rem;
  color: #fff;
}
.b_btn >>> .el-button {
  width: 100%;
}
.b_inp .el-select{
  width: 100%;
}
.btm_txt{
  color: #666;
  font-size: 14px;
}
.b_inp >>> .el-input.is-disabled .el-input__inner{
  color: #606266;
}
.add_txt {
  margin-top: 0.75rem;
  color: #2982d8;
  text-align: right;
  font-size: 0.875rem;
}
.data_box {
  border-top: 0.0625rem solid #f5f6f7;
  box-sizing: border-box;
  padding-top: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.d_box_right {
  width: 10%;
}
.d_box_right i {
  font-size: 1.5rem;
  vertical-align: middle;
}
.d_box_left {
  display: flex;
  flex: 1;
  align-items: center;
}
.l_left {
  color: #666;
  font-size: 0.875rem;
}
.l_left > div {
  margin-bottom: 0.75rem;
}
.l_left > div:last-child {
  margin-bottom: 0;
}
.l_right {
  color: #353535;
  font-weight: 600;
  padding-left: 0.75rem;
}
</style>

